<template>
  <div class="md-layout text-center login-fix-page">
    <div class="md-layout-item md-size-100">
      <div class="text-center">
        <h3>
          Olá! Seja bem-vindo ao Mosaico sistema da S3 Biotech - Sistema e
          Soluções em Saúde
        </h3>
        <p>
          Para ter a acesso ao nosso DashBord de demostração você pode realizar
          um cadastro.
        </p>
      </div>
      <div class="text-center" style="margin-bottom: 32px;">
        <h5>
          <strong>Caso ja tenha cadastro pode realizar seu login:</strong>
        </h5>
      </div>
    </div>

    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <form @submit.prevent="login">
        <login-card header-color="indigo">
          <h4 slot="title" class="title">Login</h4>
          <p slot="description" class="description">Mosaico</p>
          <md-field
            class="form-group md-invalid"
            slot="inputs"
            style="margin-bottom: 28px"
          >
            <md-icon>email</md-icon>
            <label>Email...</label>
            <md-input v-model="user.email" type="email" />
            <validation-error :errors="apiValidationErrors.email" />
          </md-field>
          <md-field class="form-group md-invalid" slot="inputs">
            <md-icon>lock_outline</md-icon>
            <label>Password...</label>
            <md-input v-model="user.password" type="password" />
          </md-field>
          <div
            class="md-layout md-gutter md-alignment-center-center"
            slot="footer"
          >
            <md-button
              type="submit"
              class="md-raised md-indigo md-layout-item md-size-80"
            >
              Login
            </md-button>

            <md-button
              href="/register"
              class="md-raised md-success md-layout-item md-size-80"
            >
              Cadastro
            </md-button>
            <!--
            <md-button
              to="/mosaico/datasets/list"
              class="md-raised md-white md-layout-item md-size-80"
            >
              <div class="card-icon">
                <div class="md-icon">
                  <img :src="google" alt="Google" />
                </div>
              </div>
              Google
            </md-button>
            -->
            <md-button
              href="/forgot-password"
              class="md-raised md-danger md-layout-item md-size-80"
            >
              Recuperar Senha
            </md-button>
          </div>
        </login-card>
      </form>
    </div>
  </div>
</template>

<script>
import { LoginCard, ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";

export default {
  components: {
    LoginCard,
    ValidationError
  },
  mixins: [formMixin],
  computed: {
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated();
    }
  },
  data() {
    return {
      user: {
        email: null,
        password: null
      }
    };
  },
  props: {
    google: {
      type: String,
      default: require("@/assets/img/google.png")
    }
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch("auth/login", this.user);
      } catch (e) {
        await this.$store.dispatch("alerts/error", "Invalid credentials!");
        this.setApiValidation(e.response.data.errors);
      }
    }
  }
};
</script>
<style scoped>
.login-fix-page {
  padding-bottom: 7em;
  padding-top: 4em;
}
</style>
